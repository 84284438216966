import { getChannelInfo, getUserInfoByUid } from '@/api/userApi';
const checkOnboardingInfo = ({
  channelCategory,
  privacy,
}: {
  channelCategory?: string;
  privacy?: {
    birthday: string;
    gender: string;
  };
}) => {
  if (!Boolean(channelCategory)) return true;

  if (
    (channelCategory === 'player' || channelCategory === 'common') &&
    !Boolean(privacy)
  )
    return true;

  return false;
};

const getIsNeedOnboarding = async ({
  uid,
  token,
}: {
  uid: string;
  token: string;
}) => {
  const {
    data: { users },
  } = await getUserInfoByUid({
    uid,
    token,
  });
  const handle = `@${users[0].nickname}`;

  const { data: channelInfo } = await getChannelInfo({
    handle,
    idToken: token,
  });

  const isNeedOnboarding = checkOnboardingInfo({
    channelCategory: channelInfo?.category,
    privacy: channelInfo?.privacy,
  });

  return isNeedOnboarding;
};

export default getIsNeedOnboarding;
